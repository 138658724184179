import * as React from 'react';

export default (year, slug, count) => {
    let items = [];

    for (let ii = 0; ii < count; ii++) {
        let num = (ii + 1).toString();

        if (num.length == 1) {
            num = `0${num}`;
        }

        // the numbering may be off
        try {
            const image = require(`../assets/images/parade/${year}/${slug}/${slug}-house${num}.jpg`);
            const thumb = require(`../assets/images/parade/${year}/${slug}/thumbs/${slug}-house${num}.jpg`);

            items.push(
                <dl class="gallery-item">
                    <dt class="gallery-icon landscape">
                    <a href={image}>
                        <img 
                            width="150" height="150" 
                            src={thumb} 
                            className="attachment-thumbnail size-thumbnail" 
                            alt="" 
                        />
                    </a>
                    </dt>
                </dl>
            );
        } catch (ex) {
            continue;
        }
    }
    return (
        <div>
            <h2>{year}</h2>
            <div class="gallery galleryid-82 gallery-columns-4 gallery-size-thumbnail">
                {items}
            </div>
        </div>
    );
};