import * as React from "react"
import Layout from '../templates/page-layout';
import ParadeList from '../components/parade-list';

export default () => {
  return (

      <Layout title="Parade of Homes">
        <p>Kahler Homes participates in Midland’s Parade of Homes, and we are the winner of the 2016 and 2019 Parade of Homes Best in Show, Favorite Outdoor Living, Favorite Floor Plan, Favorite Curb Appeal, and Favorite Interior Design!</p>

        {ParadeList(2020, 'parade-2020', 60)}

        {ParadeList(2019, 'parade-2019', 20)}

        {ParadeList(2016, 'turtle-creek', 50)}

      </Layout>
    
  )
}

